import { useSelector } from 'react-redux';

import { getCurrency, L10nNumber } from '@travel/i18n';

export type Props = {
  price: number;
};

export const KRW_TEN_THOUSAND_SYMBOL = '만';
export const THOUSAND_SYMBOL = 'K';
export const BILLION_SYMBOL = 'Bn';

export const THOUSAND = 1000;
export const TEN_THOUSAND = 10 * THOUSAND;
export const MILLION = 1000 * THOUSAND;
export const BILLION = THOUSAND * MILLION;

// check PRD for the abbreviate rule
// https://confluence.rakuten-it.com/confluence/pages/viewpage.action?pageId=4012517515
function PriceAbbreviation({ price }: Props) {
  const currency = useSelector(getCurrency);

  if (currency === 'KRW') {
    return price < MILLION ? (
      <L10nNumber value={Math.round(price)} />
    ) : (
      <>
        <L10nNumber value={Math.round(price / TEN_THOUSAND)} />
        {KRW_TEN_THOUSAND_SYMBOL}
      </>
    );
  }

  switch (true) {
    case price >= BILLION:
      return (
        <>
          <L10nNumber value={Math.round(price / BILLION)} /> {BILLION_SYMBOL}
        </>
      );
    case price >= MILLION:
      return (
        <>
          <L10nNumber value={Math.round(price / THOUSAND)} />
          {THOUSAND_SYMBOL}
        </>
      );
    default:
      return <L10nNumber value={Math.round(price)} />;
  }
}

export default PriceAbbreviation;
