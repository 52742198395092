import React from 'react';

import { Skeleton } from '@travel/ui';
import cx from '@travel/ui/utils/classnames';

import styles from './couponSkeleton.module.scss';

type Props = {
  className?: string;
  isCouponAccordionOpen?: boolean;
};

function CouponSkeleton(props: Props) {
  const { className, isCouponAccordionOpen } = props;
  const CONDITION_LINES = 5;

  return (
    <div className={cx(styles.wrapper, className)}>
      <Skeleton className={styles.couponCover} type={'image'} height={'100px'} />
      <Skeleton className={styles.button} type={'image'} height={'35px'} />
      {isCouponAccordionOpen ? (
        <>
          {Array.from({ length: CONDITION_LINES }).map((_, index) => (
            <Skeleton
              key={index}
              className={styles.conditions}
              type={'paragraph'}
              length={'long'}
            />
          ))}
        </>
      ) : (
        <div className={styles.accordionWrapper}>
          <Skeleton className={styles.accordion} type={'image'} width={'130px'} height={'20px'} />
        </div>
      )}
    </div>
  );
}

export default CouponSkeleton;
