import React from 'react';

import { IconText } from '@travel/ui';
import { Info } from '@travel/icons/ui';
import { cx } from '@travel/utils';

import ErrorMessage from '../ErrorMessage';

import styles from './couponSearchError.module.scss';

export type Status = 'error' | 'warn';

export type Props = {
  /** text or node for popup content */
  message?: React.ReactNode;
  /** status to define the color of icon */
  status?: Status;
};

function CouponSearchError(props: Props) {
  if (!props.message) return null;

  return props.status === 'warn' ? (
    <IconText
      text={props.message}
      icon={
        <Info size={16} className={styles.infoIcon} data-testid="couponSearchError-info-icon" />
      }
      className={cx(styles.wrapper, styles.warningWrapper)}
      spaceBetween={4}
      data-testid="couponSearchError-error"
    />
  ) : (
    <ErrorMessage
      className={cx(styles.wrapper, styles.errorWrapper)}
      iconClassName={styles.icon}
      message={props.message}
      hasTransparentBackground={true}
    />
  );
}

export default CouponSearchError;
