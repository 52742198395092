import React from 'react';

import CouponDate from '@travel/traveler-core/components/CouponDate';
import cx from '@travel/ui/utils/classnames';

import IncentiveValueTitle from 'components/IncentiveValueTitle';

import { CouponDetails } from 'SalesPromotion-Types';

import styles from './couponHeader.module.scss';

type Props = {
  className?: string;
  couponDetail: CouponDetails;
  currency: string;
  localCurrency?: string;
};

function CouponHeader(props: Props) {
  const { className, couponDetail, currency, localCurrency } = props;
  return (
    <div className={cx(styles.wrapper, className)}>
      <IncentiveValueTitle
        className={styles.incentive}
        localAmountClassName={styles.localAmount}
        {...couponDetail?.incentiveAmount}
        isShowCouponIcon={true}
        currency={currency}
        localCurrency={localCurrency}
      />
      {couponDetail?.title && <div className={styles.title}>{couponDetail?.title}</div>}
      <div className={styles.promotionPeriod}>
        <CouponDate
          promotionStart={couponDetail?.promotionStart}
          promotionEnd={couponDetail?.promotionEnd}
        />
      </div>
    </div>
  );
}

export default CouponHeader;
