import dayjs, { ConfigType as DateType } from 'dayjs';
import React from 'react';

import cx from '../../utils/classnames';

import styles from './calendarLabel.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Date to define the calendar month and year
   * (eg. 2019-10-29, the label will be rendered as October, 2019 )
   */
  calendarDate: DateType;
  /** Array of string to define title of month */
  monthLabels?: string[];
  /** Custom format for display */
  labelDateFormat?: string;
  /** Id for accessibility purposes. The month label describes each individual date */
  id?: string;
};

function CalendarLabel(props: Props) {
  const { className, calendarDate, monthLabels, labelDateFormat, id, ...rest } = props;
  const day = dayjs(calendarDate);

  // M: 1-12
  const monthLabel = monthLabels?.[parseInt(day.format('M')) - 1] || day.format('MMM');
  const yearLabel = day.format('YYYY');

  return (
    <span id={id} aria-live="polite" className={cx(className, styles.label)} {...rest}>
      {labelDateFormat ? day.format(labelDateFormat) : `${monthLabel} ${yearLabel}`}
    </span>
  );
}

export default React.memo(CalendarLabel);
