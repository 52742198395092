import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { VacancyCalendarState } from './reducer';

export const getErrors = (state: RootState) => state.vacancyCalendar.errors;

export const getVacancyCalendar = (state: RootState) => state.vacancyCalendar;

export const getItem = createSelector(
  getVacancyCalendar,
  (state: VacancyCalendarState) => state.item,
);

export const getDetails = createSelector(
  getVacancyCalendar,
  (state: VacancyCalendarState) => state.details,
);

export const getIsFetching = createSelector(
  getVacancyCalendar,
  (state: VacancyCalendarState) => state.isFetching,
);
