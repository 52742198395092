import React from 'react';

import { Calendar } from '@travel/icons/ui';
import { useTranslation } from '@travel/traveler-core/hooks';
import { FlatButton } from '@travel/ui';
import { cx } from '@travel/utils';

import styles from './simpleDateButton.module.scss';

type Props = {
  className?: string;
  iconClassName?: string;
  /** Date string of start date */
  startDate?: string;
  /** Date string of end date */
  endDate?: string;
  /** Size of Calendar icon */
  iconSize?: number;
  /** React node of dateless text */
  customDatelessText?: string | React.ReactNode;
  /** Callback to be called when button is clicked */
  onClick: () => void;
  /** Callback to be called when button is focused */
  onFocus: (event: React.FocusEvent) => void;
  /** Date component to specify custom format */
  DateComponent: React.ComponentType<{ value: string }>;
  /** Button ref */
  buttonRef?: React.RefObject<HTMLButtonElement>;
  /** ARIA label for button */
  ariaLabel?: string;
};

function SimpleDateButton(props: Props) {
  const {
    startDate,
    endDate,
    onClick,
    onFocus,
    customDatelessText,
    DateComponent,
    buttonRef,
    ariaLabel,
  } = props;
  const checkIn = useTranslation({ id: 'Top.Dates.Check_in' });
  const checkOut = useTranslation({ id: 'Top.Dates.Check_out' });

  let displayText;
  if (!startDate && !endDate) {
    displayText = customDatelessText || `${checkIn} - ${checkOut}`;
  } else {
    const startDateText = startDate && <DateComponent value={startDate} />;
    const endDateText = endDate && <DateComponent value={endDate} />;

    displayText = (
      <>
        {startDate ? startDateText : checkIn}&nbsp;-&nbsp;{endDate ? endDateText : checkOut}
      </>
    );
  }

  return (
    <FlatButton
      classType="secondary"
      isSmallerButtonByDefault
      icon={
        <Calendar className={props.iconClassName} size={props.iconSize || 16} color="cilantro" />
      }
      onClick={onClick}
      onFocus={onFocus}
      className={cx(styles.button, props.className)}
      data-testid="simpleDateButton-button"
      innerRef={buttonRef}
      ariaLabel={ariaLabel}
      labelClassName={styles.label}
    >
      {displayText}
    </FlatButton>
  );
}

SimpleDateButton.defaultProps = {
  customDatelessText: '',
  DateComponent: ({ value }: { value: string }) => value,
};

export default SimpleDateButton;
