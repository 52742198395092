import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { SelectedState, DayState } from './CalendarDay';

dayjs.extend(isBetween);
dayjs.extend(isoWeek);

type DayOptionalType = '' | 0 | dayjs.Dayjs | null;
type WeekType = 'week' | 'isoWeek';

export function getDaySelectedState(
  day: Dayjs,
  startRangeDay?: DayOptionalType,
  endRangeDay?: DayOptionalType,
  hoveredDay?: DayOptionalType,
): SelectedState {
  if (startRangeDay && endRangeDay) {
    const isSameStartDay = day.isSame(startRangeDay);
    const isSameEndDay = day.isSame(endRangeDay);

    if (isSameStartDay && isSameEndDay) return 'selected';
    if (isSameStartDay) return 'selectedStartDate';
    if (isSameEndDay) return 'selectedEndDate';
    if (day.isBetween(startRangeDay, endRangeDay)) return 'inSelectedRange';
  } else if (startRangeDay) {
    // if hovered date is defined with only startRangeDay
    if (hoveredDay && hoveredDay.isAfter(startRangeDay)) {
      if (day.isSame(startRangeDay)) return 'selectedStartDate';
      if (day.isSame(hoveredDay)) return 'hoveredEndDate';
      if (day.isBetween(startRangeDay, hoveredDay)) return 'inSelectedRange';
    }
    // if only startRangeDay defined
    if (day.isSame(startRangeDay)) return 'selected';
  } else if (endRangeDay) {
    // if hovered date is defined with only endRangeDay
    if (hoveredDay && hoveredDay.isBefore(endRangeDay)) {
      if (day.isSame(endRangeDay)) return 'selectedEndDate';
      if (day.isSame(hoveredDay)) return 'hoveredStartDate';
      if (day.isBetween(endRangeDay, hoveredDay)) return 'inSelectedRange';
    }
    // if only startRangeDay defined
    if (day.isSame(endRangeDay)) return 'selected';
  }

  return 'unSelected';
}

export function getDayState(
  day: Dayjs,
  calendarDay: Dayjs,
  referenceDay?: DayOptionalType,
  referenceEndDay?: DayOptionalType,
  weekType?: WeekType,
): DayState {
  if (!day.isSame(calendarDay, 'month')) return 'invalidMonth';
  if (referenceDay && day.isBefore(referenceDay)) return 'outDated';
  if (referenceEndDay && day.isAfter(referenceEndDay)) return 'outDated';

  const dayNumber = day.isoWeekday();
  if (weekType === 'week' && (dayNumber === 6 || dayNumber === 7)) return 'weekend';
  if (weekType === 'isoWeek' && dayNumber === 7) return 'weekend';
  return 'default';
}

const DAYS_PER_WEEK = 7;

export function generateMonthWeeks(calendarDay: Dayjs, weekType?: WeekType): Array<Array<Dayjs>> {
  const firstDay = calendarDay.startOf('month');
  const endDay = calendarDay.endOf('month');
  const weekTypeValue = (weekType as any) || 'week';
  // using week count for generating array of week
  const firstWeekStartDay = firstDay.startOf(weekTypeValue);
  const lastWeekEndDay = endDay.endOf(weekTypeValue);
  const weekCount = Math.ceil(lastWeekEndDay.diff(firstWeekStartDay, 'week', true));

  // generate array of week which contains week days
  let weeks: Array<Array<dayjs.Dayjs>> = [];

  for (let weekIndex = 0; weekIndex < weekCount; weekIndex++) {
    let days: Array<dayjs.Dayjs> = [];
    // using firstWeekStartDay as a reference
    let weekStartDay = dayjs(firstWeekStartDay).add(DAYS_PER_WEEK * weekIndex, 'day');
    let weekEndDay = weekStartDay.endOf(weekTypeValue);
    let dayCountInWeek = Math.ceil(weekEndDay.diff(weekStartDay, 'day'));

    for (let dayIndex = 0; dayIndex <= dayCountInWeek; dayIndex++) {
      const day = weekStartDay.add(dayIndex, 'day');

      if (day.isSame(calendarDay, 'month')) {
        days.push(day);
      } else {
        // if there are dates which are outside the current calendar,
        // in order to fix cross-month selected date issue
        if (weekIndex === 0) {
          // ;On first week, we will put the last date of previous month
          days.push(calendarDay.subtract(1, 'month').endOf('month'));
        } else {
          // ;On last week, we will put the first date of next month
          days.push(calendarDay.add(1, 'month').startOf('month'));
        }
      }
    }

    weeks.push(days);
  }

  return weeks;
}
