import React from 'react';
import cx from '../../utils/classnames';
import styles from './calendarWeekHeader.module.scss';

import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Label format:
   * 'dd': Su
   * 'ddd': Sun
   * 'dddd': Sunday
   */
  format?: 'dd' | 'ddd' | 'dddd';
  /** Custom style for item */
  itemClassName?: string;
  /** week type:
   * 'week': start from Sunday
   * 'isoWeek': start from Monday
   */
  weekType?: 'week' | 'isoWeek';
  /** Array of string to define title of day (normal-ordering) */
  weekdayLabels?: string[];
};

const DAYS_PER_WEEK = 7;

function CalendarWeekHeader(props: Props) {
  const { className, format = 'ddd', itemClassName, weekType, weekdayLabels, ...rest } = props;

  const DAY_START = dayjs().startOf((weekType as any) || 'week');
  let dayNameArray: Array<string> = [];

  // generate array of day name
  for (let day = 0; day < DAYS_PER_WEEK; day++) {
    const currentDay = DAY_START.add(day, 'day');
    const dayName =
      weekdayLabels?.[parseInt(currentDay.format('day'))] || currentDay.format(format);
    dayNameArray.push(dayName);
  }

  return (
    <div className={cx(className, styles.wrapper)} {...rest}>
      {dayNameArray.map(day => (
        <span key={day} className={cx(styles.item, itemClassName)}>
          {day}
        </span>
      ))}
    </div>
  );
}

export default React.memo(CalendarWeekHeader);
