import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Bed } from '@travel/icons/service';
import { IconText } from '@travel/ui';
import { PerDayComponentProps } from '@travel/ui/components/DatePicker';
import isNotEmptyArray from '@travel/ui/utils/arrays';
import cx from '@travel/ui/utils/classnames';

import { getErrors, getItem } from 'store/vacancyCalendar/selectors';

import { Calendars } from 'VacancyCalendar-Types';

import PriceAbbreviation from '../PriceAbbreviation';

import styles from './vacancyInfo.module.scss';

const NUM_AVAILABLE_ROOMS_CIRCLE_CUTOFF = 10;

const isAvailable = (targetRoom: Calendars) => {
  return targetRoom.availableRooms > 0;
};

function VacancyInfo(props: PerDayComponentProps) {
  const { day, selectedState } = props;

  const item = useSelector(getItem);
  const errors = useSelector(getErrors);

  const hasErrors = isNotEmptyArray(errors) && errors[0];

  const vacancyCalendarDatesMap = useMemo(
    () =>
      new Map<string, Calendars>(
        item?.calendars?.map(vcDate => [vcDate.accommodationDate, vcDate]),
      ),
    [item],
  );

  const today = dayjs().format('YYYY-MM-DD');
  const targetRoom = vacancyCalendarDatesMap.get(day.format('YYYY-MM-DD'));
  const isSelected = selectedState === 'selected';
  const isSelectedStartDate = selectedState === 'selectedStartDate';
  const isSelectedEndDate = selectedState === 'selectedEndDate';
  const isSelectedState = isSelected || isSelectedStartDate || isSelectedEndDate;

  if (
    !targetRoom ||
    !isAvailable(targetRoom) ||
    (isSelectedEndDate && !hasErrors) ||
    day.isBefore(today)
  ) {
    return (
      <div
        className={cx(
          styles.columnCentered,
          styles.roomCountInfoSection,
          isSelectedStartDate && styles.selectedDayState,
        )}
        data-testid="vacancyCalendar-perDayComponent-noRooms"
      >
        --
      </div>
    );
  }

  const symbol =
    targetRoom.availableRooms <= NUM_AVAILABLE_ROOMS_CIRCLE_CUTOFF ? (
      <div
        className={cx(isSelectedState && styles.selectedDayState)}
        data-testid="vacancyCalendar-perDayComponent-smallAmountRooms"
      >
        {targetRoom.availableRooms}
      </div>
    ) : (
      <div
        className={cx(isSelectedState && styles.selectedDayState)}
        data-testid="vacancyCalendar-perDayComponent-largeAmountRooms"
      >
        {NUM_AVAILABLE_ROOMS_CIRCLE_CUTOFF}+
      </div>
    );

  return (
    <div
      className={cx(styles.columnCentered, styles.roomCountInfoSection)}
      data-testid="vacancyCalendar-perDayComponent-wrapper"
    >
      <div className={cx(styles.teenyText, isSelectedState && styles.selectedDayState)}>
        <PriceAbbreviation price={targetRoom.priceTotal} />
      </div>
      <IconText
        className={styles.bed}
        text={symbol}
        icon={<Bed size={12} color={isSelectedState ? 'white' : 'sonicSilver'} />}
        spaceBetween={4}
      />
    </div>
  );
}

export default VacancyInfo;
