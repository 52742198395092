import dayjs, { ConfigType as DateType, Dayjs } from 'dayjs';
import React, { forwardRef } from 'react';

import { cx } from '@travel/utils';

import CalendarBody, { CalendarBodyHandle } from '../CalendarBody';
import CalendarLabel from '../CalendarLabel';
import CalendarWeekHeader from '../CalendarWeekHeader';

import { PerDayComponentProps } from './';

import styles from './datePicker.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  style?: React.CSSProperties;
  /** Date to define the calendar month and year
   * (eg. 2019-10-29, the calendar will be rendered as October, 2019 )
   */
  calendarDate: DateType;
  /** Custom style for calendar day */
  dayItemClassName?: string;
  /** End date of selected range */
  endDate?: string;
  /** Date which is being hovered */
  hoveredDate?: string;
  /** Date to reference (usually, be used as current date) */
  referenceDate?: string;
  /** Start date of selected range */
  startDate?: string;
  /** callback function when calendar day is clicked */
  onClickDayItem: (event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => void;
  /** callback function when calendar day is being hover */
  onHoverDayItem?: (event: React.MouseEvent | React.FocusEvent, day?: Dayjs) => void;
  /** Flag to define whether the CalendarWeekHeader will be shown on calendars or not */
  isShowWeekHeader: boolean;
  /** week type:
   * 'week': start from Sunday
   * 'isoWeek': start from Monday
   */
  weekType?: 'week' | 'isoWeek';
  /** Array of string to define title of day (normal-ordering) */
  weekdayLabels?: string[];
  /** Array of string to define title of month */
  monthLabels?: string[];
  /** Date to end of reference */
  referenceEndDate?: DateType;
  /** Custom style for wrapper of calendar day */
  dayWrapperClassName?: string;
  /** Extra component to display on calendar per day */
  PerDayComponent?: React.ComponentType<PerDayComponentProps>;
  /** Custom format for display */
  labelDateFormat?: string;
  /** Custom style for wrapper of calendar month labels */
  calendarLabelClassName?: string;
  /** Custom style for wrapper of calendar week headers */
  calendarWeekHeaderClassName?: string;
  /** Custom style for calendar body wrapper (table) */
  calendarBodyClass?: string;
  /** Date Selection Highlight type (default circle) */
  highlightType?: 'circle' | 'full';
  /** Flag to highlight the current date */
  shouldShowCurrentDate?: boolean;
};

const Calendar = forwardRef<CalendarBodyHandle, Props>(function Calendar(
  props: Props,
  ref: React.ForwardedRef<CalendarBodyHandle>,
) {
  const {
    className,
    style,
    calendarDate,
    isShowWeekHeader,
    weekType,
    weekdayLabels,
    monthLabels,
    labelDateFormat,
    calendarWeekHeaderClassName,
    calendarBodyClass,
    calendarLabelClassName,
    ...rest
  } = props;

  const day = dayjs(calendarDate);
  const accessibilityId = '' + monthLabels?.[parseInt(day.format('M')) - 1];

  return (
    <div className={cx(className, styles.calendar)} style={style}>
      <CalendarLabel
        className={cx(styles.calendarLabel, calendarLabelClassName)}
        calendarDate={calendarDate}
        monthLabels={monthLabels}
        labelDateFormat={labelDateFormat}
        id={accessibilityId}
      />
      {isShowWeekHeader && (
        <CalendarWeekHeader
          className={cx(styles.calendarWeekHeader, calendarWeekHeaderClassName)}
          itemClassName={styles.calendarWeekHeaderItem}
          weekType={weekType}
          weekdayLabels={weekdayLabels}
        />
      )}
      <CalendarBody
        className={cx(styles.calendarBody, calendarBodyClass)}
        calendarDate={calendarDate}
        weekType={weekType}
        ariaDescribedBy={accessibilityId}
        ref={ref}
        {...rest}
      />
    </div>
  );
});

Calendar.defaultProps = {
  className: '',
  dayItemClassName: '',
  onClickDayItem: () => {},
  isShowWeekHeader: true,
};

export default React.memo(Calendar);
