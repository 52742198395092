import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

function useEffectExceptForFirstRendering(func: EffectCallback, deps: DependencyList) {
  const isFirstRendering = useRef(true);

  useEffect(() => {
    if (isFirstRendering.current) {
      isFirstRendering.current = false;
    } else {
      func();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export default useEffectExceptForFirstRendering;
