import React from 'react';

import { useLocaleWeekAndMonth } from '@travel/i18n';
import { useTranslation } from '@travel/traveler-core/hooks';
import { DatePicker } from '@travel/ui';
import { Props as DateProps } from '@travel/ui/components/DatePicker';

export type Props = DateProps & {
  className?: string;
  /** Flag to enable animation when Dialog mounted */
  hasAnimationDialog?: boolean;
};

function LocaleDatePicker(props: Props) {
  const { weekdaysShort, monthsShort, firstDayOfWeek } = useLocaleWeekAndMonth();
  const { clearLabelText, startDateButtonAriaLabel, endDateButtonAriaLabel } = props;

  return (
    <DatePicker
      {...props}
      // localization
      weekdayLabels={weekdaysShort}
      monthLabels={monthsShort}
      weekType={firstDayOfWeek ? 'isoWeek' : 'week'}
      // translation
      clearLabelText={
        clearLabelText ? clearLabelText : useTranslation({ id: 'Top.Modals.Calendar.Clear' })
      }
      startDateButtonAriaLabel={startDateButtonAriaLabel}
      endDateButtonAriaLabel={endDateButtonAriaLabel}
    />
  );
}

export default React.memo(LocaleDatePicker);
