import dayjs, { ConfigType as DateType, Dayjs } from 'dayjs';
import React, { ForwardedRef, forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { PerDayComponentProps } from '../DatePicker';
import CalendarDay, { CalendarDayHandle } from './CalendarDay';

import cx from '../../utils/classnames';
import { generateMonthWeeks, getDaySelectedState, getDayState } from './helpers';

import styles from './calendarBody.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Date to define the calendar month and year
   * (eg. 2019-10-29, the calendar will be rendered as October, 2019 )
   */
  calendarDate: DateType;
  /** Custom style for calendar day */
  dayItemClassName?: string;
  /** End date of selected range */
  endDate?: DateType;
  /** Date which is being hovered */
  hoveredDate?: DateType;
  /** disable month navigation arrows from the calendar */
  isMonthNavigationDisabled?: boolean;
  /** Date to reference (usually, be used as current date) */
  referenceDate?: DateType;
  /** Start date of selected range */
  startDate?: DateType;
  /** callback function when calendar day is clicked */
  onClickDayItem: (event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => void;
  /** callback function when calendar day is being hover */
  onHoverDayItem?: (event: React.MouseEvent | React.FocusEvent, day?: Dayjs) => void;
  /** week type:
   * 'week': start from Sunday
   * 'isoWeek': start from Monday
   */
  weekType?: 'week' | 'isoWeek';
  /** Date to end of reference  */
  referenceEndDate?: DateType;
  /** Custom style for wrapper of calendar day */
  dayWrapperClassName?: string;
  /** Extra component to display on calendar per day */
  PerDayComponent?: React.ComponentType<PerDayComponentProps>;
  /** Date Selection Highlight type (default circle) */
  highlightType?: 'circle' | 'full';
  /** Flag to highlight the current date */
  shouldShowCurrentDate?: boolean;
  /** Id of month label that describes the date for accessibility */
  ariaDescribedBy?: string;
};

export type CalendarBodyHandle = {
  focusFirst: () => void;
  focusLast: () => void;
  focusNext: () => void;
  focusPrevious: () => void;
  focusDown: () => void;
  focusUp: () => void;
};

// TODO: Check performance issue when applied with DatePicker
const CalendarBody = forwardRef<CalendarBodyHandle, Props>(function CalendarBody(
  props: Props,
  ref: ForwardedRef<CalendarBodyHandle>,
) {
  const {
    className,
    calendarDate,
    endDate,
    hoveredDate,
    dayItemClassName,
    isMonthNavigationDisabled,
    onClickDayItem,
    onHoverDayItem,
    referenceDate,
    startDate,
    weekType,
    referenceEndDate,
    dayWrapperClassName,
    PerDayComponent,
    highlightType = 'circle',
    shouldShowCurrentDate,
    ariaDescribedBy,
    ...rest
  } = props;

  const calendarDay = useMemo(() => dayjs(calendarDate), [calendarDate]);
  const weeks = useMemo(() => generateMonthWeeks(calendarDay, weekType), [calendarDay, weekType]);

  const referenceDay = useMemo(() => referenceDate && dayjs(referenceDate), [referenceDate]);
  const referenceEndDay = useMemo(() => referenceEndDate && dayjs(referenceEndDate), [
    referenceEndDate,
  ]);
  const startRangeDay = useMemo(() => startDate && dayjs(startDate), [startDate]);
  const endRangeDay = useMemo(() => endDate && dayjs(endDate), [endDate]);

  // hoveredDate can be changed many times, so we don't need to use useMemo()
  const hoveredDay = hoveredDate && dayjs(hoveredDate);

  const refs = useRef<(CalendarDayHandle | null)[]>([]);
  useImperativeHandle(
    ref,
    () => {
      const hoveredDay = dayjs(hoveredDate);
      const date = hoveredDay.date();
      return {
        focusFirst: () => refs.current[1]?.focus(), // why 1 and not 0? Because we create the refs array using an index of the date in the month and dates start with 1
        focusLast: () => refs.current[calendarDay.daysInMonth()]?.focus(),
        focusNext: () => refs.current[date + 1]?.focus(),
        focusPrevious: () => refs.current[date - 1]?.focus(),
        focusDown: () => refs.current[date + 7]?.focus(),
        focusUp: () => refs.current[date - 7]?.focus(),
      };
    },
    [hoveredDate, calendarDay],
  );

  return (
    <table className={cx(className, styles.wrapper)} {...rest}>
      <tbody data-testid="calendarBody-table-body">
        {weeks.map((days, index) => (
          <tr key={index} data-testid={'CalendarBody-tr'}>
            {days.map((day, index) => {
              const dayState = getDayState(
                day,
                calendarDay,
                referenceDay,
                referenceEndDay,
                weekType,
              );
              const selectedState = getDaySelectedState(
                day,
                startRangeDay,
                endRangeDay,
                hoveredDay,
              );

              const month = day.month();
              const isFocusable = dayState !== 'outDated' && dayState !== 'invalidMonth';
              const isSelectedOrDefault =
                selectedState === 'selected' ||
                selectedState === 'selectedStartDate' ||
                selectedState === 'selectedEndDate' ||
                (!startDate && !endDate && day.isSame(referenceDay)) ||
                (month !== dayjs(startDate).month() &&
                  month !== dayjs(endDate).month() &&
                  month !== dayjs(referenceDate).month() &&
                  day.date() === 1);

              return (
                <CalendarDay
                  // Using index as a key in order to fix cross-month selected date issue,
                  // due to some date in week might be duplicated
                  // pls see: generateMonthWeeks (./helpers)
                  key={`${day.format('D')}-${index}`}
                  day={day}
                  dayState={dayState}
                  itemClassName={dayItemClassName}
                  className={dayWrapperClassName}
                  selectedState={selectedState}
                  onClick={onClickDayItem}
                  onHover={onHoverDayItem}
                  extraComponent={
                    PerDayComponent && dayState !== 'invalidMonth' ? (
                      <PerDayComponent day={day} selectedState={selectedState} />
                    ) : (
                      undefined
                    )
                  }
                  highlightType={highlightType}
                  shouldShowCurrentDate={shouldShowCurrentDate}
                  ref={ref => isFocusable && (refs.current[day.date()] = ref)}
                  tabindex={isFocusable ? (isSelectedOrDefault ? 0 : -1) : undefined}
                  ariaDescribedBy={ariaDescribedBy}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default CalendarBody;
