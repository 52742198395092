import { Dayjs } from 'dayjs';
import React, { useCallback, RefObject } from 'react';

import { LoadingMask } from '@travel/ui';

import { SelectedState } from '../CalendarBody/CalendarDay';
import CalendarWeekHeader from '../CalendarWeekHeader';
import DateButtonGroup, { SelectedButton } from '../DateButtonGroup';
import Dialog, { Props as DialogProps } from '../Dialog';
import Popup from '../Popup';
import DatePickerBody from './DatePickerBody';
import SummaryRangeText from './SummaryRangeText';

import cx from '../../utils/classnames';

import styles from './datePicker.module.scss';

export type DisplayType = 'popup' | 'dialog';

export type PerDayComponentProps = { day: Dayjs; selectedState: SelectedState };

type PopupProps = {
  /** Anchor element */
  anchorEl?: HTMLElement | null;
  /** Custom min-width of the popup */
  minWidth?: number;
};

export type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Custom style for the Dialog */
  dialogClassName?: string;
  /** Custom style for the Action Buttons section */
  dialogActionClassName?: string;
  /** Custom style for the dayItem */
  dayItemClassName?: string;
  /** Custom props for the Dialog component
   * className, isOpen and onClose will be overridden */
  dialogProps?: Partial<DialogProps>;
  /** End date of selected range */
  endDate?: string;
  /** String to define title of end date */
  endDateLabel: React.ReactNode;
  /** Flag to define whether the date-picker will be shown or not */
  isOpen: boolean;
  /** Number of displayed months (The first month will be started from referenceDate by default) */
  numberOfMonths: number;
  /** Callback function when the date-picker is closed */
  onClose: () => void;
  /** Callback function when the clear button is clicked */
  onClear: () => void;
  /** Callback function when the date button is clicked */
  onSelectButton: (event: React.MouseEvent, button: SelectedButton) => void;
  /** Callback function when the date button is focus */
  onFocusButton?: (event: React.FocusEvent, button: SelectedButton) => void;
  /** Callback function when the day item is clicked */
  onSelectDate: (event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => void;
  /** Custom props for the custom popup component */
  popupProps?: PopupProps;
  /** Current selected button */
  selectedButton?: string;
  /** Date to define the first calendar */
  referenceDate: string;
  /** Start date of selected range */
  startDate?: string;
  /** String to define title of start date */
  startDateLabel: React.ReactNode;
  /** Decides using popup or dialog for calendar container */
  type: DisplayType;
  /** Translation labels */
  /** String to define clear button text */
  clearLabelText?: React.ReactNode;
  /** Localization values */
  /** Array of string to define title of month */
  monthLabels?: string[];
  /** Array of string to define title of day (normal-ordering) */
  weekdayLabels?: string[];
  /** week type:
   * 'week': start from Sunday
   * 'isoWeek': start from Monday
   */
  weekType?: 'week' | 'isoWeek';
  /** Date component to specify custom format */
  DateComponent: React.ComponentType<{ value: string; format?: string }>;
  /** Date to end of reference  */
  referenceEndDate?: string;
  /** Flag to define whether the dialog is displayed on top of another dialog or not */
  isDisplayedOnDialog?: boolean;
  NightsLabelComponent?: React.ComponentType<{ noOfNights: number }>;
  /** Custom style for wrapper of calendar day */
  dayWrapperClassName?: string;
  /** Extra component to display on calendar per day */
  PerDayComponent?: React.ComponentType<PerDayComponentProps>;
  /** Custom Height for Calendar of each month */
  customCalendarHeight?: number;
  /** Custom style for each Calendar component */
  calendarWrapperClassName?: string;
  /** Custom format for display */
  labelDateFormat?: string;
  /** Custom style for wrapper of calendar month labels */
  calendarLabelClassName?: string;
  /** Custom style for dialog header */
  dialogHeaderClassName?: string;
  /** Custom style for wrapper of calendar week headers */
  calendarWeekHeaderClassName?: string;
  /** Custom node for bottom info box (popup style) */
  customPopupInfoBoxComponent?: React.ReactNode;
  /** Custom node for right side info box (popup style) */
  customPopupRightInfoBoxComponent?: React.ReactNode;
  /** Custom style for calendar body wrapper (table) */
  calendarBodyClass?: string;
  /** Date Selection Highlight type (default circle) */
  highlightType?: 'circle' | 'full';
  /** Flag to highlight the current date */
  shouldShowCurrentDate?: boolean;
  /** Custom style for navigation arrows */
  navigationIconClassName?: string;
  /** Size in px of navigation arrows */
  navigationIconSize?: number;
  /** disable month navigation arrows from the calendar */
  isMonthNavigationDisabled?: boolean;
  /** Ref to modal wrapper */
  contentRef?: RefObject<{
    contains: (node: Node) => boolean;
  }>;
  /** ARIA label for start date button */
  startDateButtonAriaLabel?: string;
  /** ARIA label for end date button */
  endDateButtonAriaLabel?: string;
  /** onClick callback for clicking next / prev month button */
  onClickNavigationCallBack?: (months: string[]) => void;
  /** Status for loading */
  isLoading?: boolean;
  /** flag to disable close by outside click  */
  isClosingByOutSideClicking?: boolean;
  /** Custom title for DatePicker's dialog */
  dialogTitleString?: React.ReactNode;
  /** Flag to enable animation when Dialog mounted */
  hasAnimationDialog?: boolean;
};

function DatePicker(props: Props) {
  const {
    className,
    dialogClassName,
    dialogActionClassName,
    dayItemClassName,
    isOpen,
    popupProps,
    numberOfMonths,
    startDate,
    endDate,
    onSelectDate,
    referenceDate,
    type,
    dialogProps,
    endDateLabel,
    startDateLabel,
    onSelectButton,
    onFocusButton,
    selectedButton,
    onClose,
    onClear,
    weekType,
    weekdayLabels,
    monthLabels,
    clearLabelText,
    DateComponent,
    referenceEndDate,
    isDisplayedOnDialog,
    NightsLabelComponent,
    dayWrapperClassName,
    PerDayComponent,
    customCalendarHeight,
    calendarWrapperClassName,
    labelDateFormat,
    calendarLabelClassName,
    calendarWeekHeaderClassName,
    dialogHeaderClassName,
    customPopupInfoBoxComponent,
    calendarBodyClass,
    highlightType = 'circle',
    shouldShowCurrentDate,
    navigationIconClassName,
    navigationIconSize,
    isMonthNavigationDisabled = false,
    contentRef,
    startDateButtonAriaLabel,
    endDateButtonAriaLabel,
    customPopupRightInfoBoxComponent,
    onClickNavigationCallBack,
    isClosingByOutSideClicking,
    dialogTitleString,
    hasAnimationDialog,
  } = props;

  // selectedButton state is handled from parent
  const onClickStartDateButton = useCallback(
    (event: React.MouseEvent) => onSelectButton(event, 'startDate'),
    [onSelectButton],
  );
  const onClickEndDateButton = useCallback(
    (event: React.MouseEvent) => onSelectButton(event, 'endDate'),
    [onSelectButton],
  );

  const oFocusStartDateButton = useCallback(
    (event: React.FocusEvent) => onFocusButton?.(event, 'startDate'),
    [onFocusButton],
  );
  const onFocusEndDateButton = useCallback(
    (event: React.FocusEvent) => onFocusButton?.(event, 'endDate'),
    [onFocusButton],
  );

  const handleClear = useCallback(() => {
    onClear();
  }, [onClear]);

  // isOpen state is handled from parent
  if (!isOpen) return null;

  // default props
  const defaultDatePickerProps = {
    startDate,
    endDate,
    referenceDate,
    numberOfMonths,
    onClickDayItem: onSelectDate,
    type,
    weekType,
    weekdayLabels,
    monthLabels,
    clearLabelText,
    referenceEndDate,
    dayWrapperClassName,
    PerDayComponent,
    customCalendarHeight,
    calendarWrapperClassName,
    labelDateFormat,
    calendarLabelClassName,
    calendarWeekHeaderClassName,
    calendarBodyClass,
    highlightType,
    shouldShowCurrentDate,
    navigationIconClassName,
    navigationIconSize,
    isMonthNavigationDisabled,
    onClickNavigationCallBack,
    dayItemClassName,
  };

  const summaryRange = (
    <div className={styles.infoBox}>
      <SummaryRangeText
        startDateLabel={startDateLabel}
        endDateLabel={endDateLabel}
        startDate={startDate}
        endDate={endDate}
        DateComponent={DateComponent}
        weekdayLabels={weekdayLabels}
        NightsLabelComponent={NightsLabelComponent}
      />
      <button
        className={styles.clearButton}
        disabled={Boolean(!startDate && !endDate)}
        onClick={handleClear}
        type="button"
        data-testid="datePicker-clearButton"
      >
        {clearLabelText}
      </button>
    </div>
  );

  return (
    <>
      {isOpen && type === 'popup' && (
        <Popup
          isOpen={isOpen}
          onClose={onClose}
          className={cx(className, styles.dialogContentBox)}
          style={popupProps && { minWidth: popupProps.minWidth }}
          anchorEl={popupProps && popupProps.anchorEl}
          isClosingByOutSideClicking={isClosingByOutSideClicking}
        >
          {props?.isLoading && <LoadingMask type="section" className={styles.loading} />}
          {customPopupRightInfoBoxComponent ? (
            <div className={styles.rightBox}>
              <div className={styles.datePickerWrapper}>
                {summaryRange}
                <DatePickerBody
                  {...defaultDatePickerProps}
                  isShowNavigation={true}
                  isShowWeekHeader={true}
                />
              </div>
              {customPopupRightInfoBoxComponent}
            </div>
          ) : (
            <DatePickerBody
              {...defaultDatePickerProps}
              isShowNavigation={true}
              isShowWeekHeader={true}
            />
          )}

          {customPopupInfoBoxComponent ? customPopupInfoBoxComponent : summaryRange}
        </Popup>
      )}

      {isOpen && type === 'dialog' && (
        <Dialog
          title={
            <div className={styles.dialogTitle}>
              {dialogTitleString ||
                (selectedButton === 'startDate' ? startDateLabel : endDateLabel)}{' '}
              <button
                className={cx(styles.clearButton, styles.clearButtonInDialog)}
                disabled={Boolean(!startDate && !endDate)}
                onClick={handleClear}
                data-testid="datePicker-clearButton"
              >
                {clearLabelText}
              </button>
            </div>
          }
          {...dialogProps}
          className={cx(dialogClassName, styles.dialog)}
          isOpen={isOpen}
          onClose={onClose}
          actionClassName={cx(dialogActionClassName, styles.dialogAction)}
          contentClassName={cx(className, styles.dialogContentBox, styles.typeDialog)}
          headerClassName={cx(styles.dialogHeader, dialogHeaderClassName)}
          isUseContentFlexBox={false}
          isDisplayedOnDialog={isDisplayedOnDialog}
          contentRef={contentRef}
          hasAnimation={hasAnimationDialog}
        >
          {props?.isLoading && <LoadingMask type="section" className={styles.loading} />}
          <DateButtonGroup
            className={styles.dateButtonGroup}
            buttonClassName={styles.dialogDateButton}
            customStyle="labelWithDate"
            isShowSelectedState={true}
            startDate={startDate}
            endDate={endDate}
            selectedButton={selectedButton}
            onClickStartDateButton={onClickStartDateButton}
            onClickEndDateButton={onClickEndDateButton}
            onFocusStartDateButton={oFocusStartDateButton}
            onFocusEndDateButton={onFocusEndDateButton}
            startDateLabel={startDateLabel}
            endDateLabel={endDateLabel}
            DateComponent={DateComponent}
            startButtonAriaLabel={startDateButtonAriaLabel}
            endButtonAriaLabel={endDateButtonAriaLabel}
          />
          {customPopupRightInfoBoxComponent ? customPopupRightInfoBoxComponent : null}
          <CalendarWeekHeader
            className={cx(styles.calendarWeekHeader, calendarWeekHeaderClassName)}
            itemClassName={styles.calendarWeekHeaderItem}
            weekType={weekType}
            weekdayLabels={weekdayLabels}
          />
          <DatePickerBody
            className={styles.typeDialog}
            isShowNavigation={false}
            isShowWeekHeader={false}
            {...defaultDatePickerProps}
          />
        </Dialog>
      )}
    </>
  );
}

DatePicker.defaultProps = {
  className: '',
  isOpen: false,
  type: 'popup',
  clearLabelText: 'Clear',
  onClose: () => {},
  onClear: () => {},
  onSelectButton: () => {},
  onSelectDate: () => {},
  DateComponent: ({ value }: { value: string }) => value,
};

export default DatePicker;
