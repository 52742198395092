import dayjs from 'dayjs';

export function generateCalendarMonths(
  referenceDate: string,
  numberOfMonths: number,
): Array<string> {
  const referenceDay = dayjs(referenceDate);
  let months: Array<string> = [];

  for (let monthIndex = 0; monthIndex < numberOfMonths; monthIndex++) {
    const targetDay = referenceDay.add(monthIndex, 'month');
    months.push(targetDay.format('YYYY-MM'));
  }

  return months;
}
