import React, { ReactNode } from 'react';

import cx from '../../utils/classnames';

import styles from './incrementer.module.scss';

type Props = {
  /** Custom style for wrapper */
  className?: string;

  /** Custom style for counter */
  counterClassName?: string;

  /** Number of current counter value */
  value: ReactNode;

  /** Flag to disable minus button */
  isLeftDisabled: boolean;

  /** Flag to disable plus button */
  isRightDisabled: boolean;

  /** Callback function on decrease */
  onDecrease: (event: React.MouseEvent) => void;

  /** Callback function on increase */
  onIncrease: (event: React.MouseEvent) => void;

  /** A string indicates what action of decrement  */
  decrementAriaLabel: string;

  /** A string indicates what action of increment  */
  incrementAriaLabel: string;

  /** Increment Icon to replace default increment icon */
  incrementIcon?: ReactNode;

  /** Decrement Icon to replace default increment icon */
  decrementIcon?: ReactNode;

  /** Make counter text bold */
  isCounterBolded?: boolean;

  /** Id or ids of elements that describe the increment */
  ariaDescribedBy?: string | string[];

  /** Id or ids of elements that describe the increment */
  ariaLabelledBy?: string | string[];
};

// TODO: If svg icon component is available, need to replace
function DecreaseIcon() {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <g stroke="none" strokeWidth="1" fill="#54822B" fillRule="evenodd">
        <g transform="translate(4.000000, 4.000000)">
          <polygon points="7 13 17 13 17 11 7 11" />
          <path d="M2,22 L22,22 L22,2 L2,2 L2,22 Z M23,24 L1,24 C0.448,24 0,23.552 0,23 L0,1 C0,0.447 0.448,0 1,0 L23,0 C23.552,0 24,0.447 24,1 L24,23 C24,23.552 23.552,24 23,24 L23,24 Z" />
        </g>
      </g>
    </svg>
  );
}

// TODO: If svg icon component is available, need to replace
function IncreaseIcon() {
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32">
      <g stroke="none" strokeWidth="1" fill="#54822B" fillRule="evenodd">
        <g transform="translate(4.000000, 4.000000)">
          <polygon points="17.0002 10.9998 13.0002 10.9998 13.0002 6.9998 11.0002 6.9998 11.0002 10.9998 7.0002 10.9998 7.0002 12.9998 11.0002 12.9998 11.0002 16.9998 13.0002 16.9998 13.0002 12.9998 17.0002 12.9998" />
          <path d="M2,22 L22,22 L22,2 L2,2 L2,22 Z M23,24 L1,24 C0.448,24 0,23.552 0,23 L0,1 C0,0.447 0.448,0 1,0 L23,0 C23.552,0 24,0.447 24,1 L24,23 C24,23.552 23.552,24 23,24 L23,24 Z" />
        </g>
      </g>
    </svg>
  );
}

export default function Incrementer(props: Props) {
  const {
    className,
    counterClassName,
    value,
    isLeftDisabled,
    isRightDisabled,
    onDecrease,
    onIncrease,
    decrementAriaLabel,
    incrementAriaLabel,
    incrementIcon = IncreaseIcon(),
    decrementIcon = DecreaseIcon(),
    isCounterBolded = false,
    ariaDescribedBy,
    ariaLabelledBy,
    ...rest
  } = props;

  const leftDisabledStyles = isLeftDisabled ? styles.disabledButton : styles.enabledButton;
  const rightDisabledStyles = isRightDisabled ? styles.disabledButton : styles.enabledButton;
  const counterDisabledStyles = isLeftDisabled && isRightDisabled ? styles.disabledCounter : '';
  const ariaDescribedByString = Array.isArray(ariaDescribedBy)
    ? ariaDescribedBy.join(' ')
    : ariaDescribedBy;

  const ariaLabelledByString = Array.isArray(ariaLabelledBy)
    ? ariaLabelledBy.join(' ')
    : ariaLabelledBy;

  return (
    <div className={cx(className, styles.container)} {...rest}>
      <button
        aria-label={decrementAriaLabel}
        className={cx(styles.button, leftDisabledStyles)}
        aria-disabled={isLeftDisabled}
        onClick={onDecrease}
        data-testid={`incrementer-button-${decrementAriaLabel}`}
        disabled={isLeftDisabled}
        aria-describedby={ariaDescribedByString}
        aria-labelledby={ariaLabelledByString}
      >
        {decrementIcon}
      </button>
      <span
        className={cx(
          styles.counter,
          counterDisabledStyles,
          counterClassName,
          isCounterBolded && styles.bold,
        )}
        data-testid="incrementor-counter-div"
      >
        {value}
      </span>
      <button
        aria-label={incrementAriaLabel}
        className={cx(styles.button, rightDisabledStyles)}
        aria-disabled={isRightDisabled}
        onClick={onIncrease}
        data-testid={`incrementer-button-${incrementAriaLabel}`}
        disabled={isRightDisabled}
        aria-describedby={ariaDescribedByString}
        aria-labelledby={ariaLabelledByString}
      >
        {incrementIcon}
      </button>
    </div>
  );
}

Incrementer.defaultProps = {
  value: 1,
  isLeftDisabled: false,
  isRightDisabled: false,
  onDecrease: () => {},
  onIncrease: () => {},
  decrementAriaLabel: 'decrement',
  incrementAriaLabel: 'increment',
};
