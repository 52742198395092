import React from 'react';

import Translate from '@travel/traveler-core/components/Translate';
import { Incrementer } from '@travel/ui';

import CouponSearchError from '../CouponSearchError';

import { ValidationResponse } from './hooks';

import styles from './amountInput.module.scss';

type Props = {
  /** Number of current value */
  counter: number;
  /** Flag to define whether the left side will be disabled or not */
  isLeftDisabled: boolean;
  /** Flag to define whether the right side will be disabled or not */
  isRightDisabled: boolean;
  /** Callback to be called when the value is decreased */
  onDecrease: (event: React.MouseEvent) => void;
  /** Callback to be called when the value is increased */
  onIncrease: (event: React.MouseEvent) => void;
  /** Validation callback when state changed */
  validation?: ValidationResponse;
  /** Id or ids of elements that describe the incrementor */
  ariaDescribedBy?: string | string[];
  /** Id or ids of elements that label the incrementor */
  ariaLabelledBy?: string | string[];
};

function AdultForm(props: Props) {
  return (
    <div className={styles.amountFormWrapper}>
      <div className={styles.amountForm}>
        <Translate className={styles.amountLabel} id="Top.Modals.Rooms_Guests.Adults" />
        <Incrementer
          value={props.counter}
          isLeftDisabled={props.isLeftDisabled}
          isRightDisabled={props.isRightDisabled}
          onDecrease={props.onDecrease}
          onIncrease={props.onIncrease}
          decrementAriaLabel={'decrement adults'}
          incrementAriaLabel={'increment adults'}
          data-testid="amountInput-incrementer-adult"
          ariaDescribedBy={props.ariaDescribedBy}
          ariaLabelledBy={props.ariaLabelledBy}
        />
      </div>
      <CouponSearchError {...props.validation} />
    </div>
  );
}

export default React.memo(AdultForm);
