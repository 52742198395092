import React from 'react';

import Translate from '@travel/traveler-core/components/Translate';
import { Incrementer } from '@travel/ui';
import { cx } from '@travel/utils';

import CouponSearchError from '../CouponSearchError';

import { ValidationResponse } from './hooks';

import styles from './amountInput.module.scss';

type Props = {
  /** Number of current value */
  counter: number;
  /** Flag to define whether the left side will be disabled or not */
  isLeftDisabled: boolean;
  /** Flag to define whether the right side will be disabled or not */
  isRightDisabled: boolean;
  /** Callback to be called when the value is decreased */
  onDecrease: (event: React.MouseEvent) => void;
  /** Callback to be called when the value is increased */
  onIncrease: (event: React.MouseEvent) => void;
  /** Validation callback when state changed */
  validation?: ValidationResponse;
  /** Custom Decrement Icon */
  decrementIcon?: JSX.Element;
  /** Custom Increment Icon */
  incrementIcon?: JSX.Element;
  /** Make counter text bold */
  isCounterBolded?: boolean;
  /** Custom unit type for testing and ARIA purposes */
  customUnitType?: string;
  /** Id or ids of elements that describe the incrementor */
  ariaDescribedBy?: string | string[];
  /** Id or ids of elements that label the incrementor */
  ariaLabelledBy?: string | string[];
};

function UnitForm(props: Props) {
  return (
    <div className={cx(styles.amountFormWrapper, styles.roomForm)}>
      <div className={styles.amountForm}>
        <Translate id="Top.Modals.Rooms_Guests.Rooms" className={styles.amountLabel} />
        <Incrementer
          value={props.counter}
          isLeftDisabled={props.isLeftDisabled}
          isRightDisabled={props.isRightDisabled}
          onDecrease={props.onDecrease}
          onIncrease={props.onIncrease}
          decrementAriaLabel={`decrement ${props.customUnitType ? props.customUnitType : 'rooms'}`}
          incrementAriaLabel={`increment ${props.customUnitType ? props.customUnitType : 'rooms'}`}
          data-testid={`amountInput-incrementer-${
            props.customUnitType ? props.customUnitType : 'noOfUnit'
          }`}
          decrementIcon={props.decrementIcon}
          incrementIcon={props.incrementIcon}
          isCounterBolded={props.isCounterBolded}
          ariaDescribedBy={props.ariaDescribedBy}
          ariaLabelledBy={props.ariaLabelledBy}
        />
      </div>
      <CouponSearchError {...props.validation} />
    </div>
  );
}

export default React.memo(UnitForm);
