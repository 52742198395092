import React from 'react';
import dayjs from 'dayjs';

import { Props as DatePickerProps } from './index';

type Props = Pick<
  DatePickerProps,
  | 'startDate'
  | 'endDate'
  | 'DateComponent'
  | 'startDateLabel'
  | 'endDateLabel'
  | 'weekdayLabels'
  | 'NightsLabelComponent'
>;

function SummaryRangeText(props: Props) {
  const dayNameFormat = 'ddd';
  const {
    DateComponent,
    startDate,
    endDate,
    startDateLabel,
    endDateLabel,
    weekdayLabels,
    NightsLabelComponent,
  } = props;

  const startDay = startDate && dayjs(startDate);
  const startDateComponent = startDate && (
    <>
      {startDay &&
        (weekdayLabels?.[parseInt(startDay.format('day'))] || startDay.format(dayNameFormat))}
      ,&nbsp;
      <DateComponent value={startDate} />
    </>
  );

  const endDay = endDate && dayjs(endDate);
  const endDateComponent = endDate && (
    <>
      {endDay && (weekdayLabels?.[parseInt(endDay.format('day'))] || endDay.format(dayNameFormat))}
      ,&nbsp;
      <DateComponent value={endDate} />
    </>
  );

  const noOfNights = endDay && startDay && endDay.diff(startDay, 'day');

  // rendering
  return (
    <>
      {startDateComponent || startDateLabel}
      &nbsp;-&nbsp;
      {endDateComponent || endDateLabel}
      {noOfNights && NightsLabelComponent && (
        <>
          &nbsp;
          <NightsLabelComponent noOfNights={noOfNights} />
        </>
      )}
    </>
  );
}

export default SummaryRangeText;
