import React from 'react';

import AmountInput, { AmountInputRef, Props } from './';

const AmountInputWithRef = React.memo(
  React.forwardRef((props: Props, ref: React.Ref<AmountInputRef>) => (
    <AmountInput {...props} innerRef={ref} />
  )),
);

export default AmountInputWithRef;
