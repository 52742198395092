import React from 'react';

import Adult from '@travel/icons/service/Adult';
import Translate from '@travel/traveler-core/components/Translate';
import { cx } from '@travel/utils';

import styles from './amountInput.module.scss';

type Props = {
  className?: string;
  /** Number of current units */
  unit: number;
  /** Number of current adults */
  adults: number;
  /** Array of child age */
  childrenAges: Array<number>;
  /** Callback to be called when button is clicked */
  onClick: () => void;
  /** Callback to be called when button is clicked */
  isPartialTextStyle: boolean;
  /** ARIA label for button */
  ariaLabel?: string;
};

function TextButton(props: Props) {
  return (
    <button
      type="button"
      className={cx(
        props.className,
        styles.formButton,
        props.isPartialTextStyle && styles.formButtonSmall,
      )}
      onClick={props.onClick}
      data-testid="amountInput-fullText-button"
      aria-live="polite"
      aria-label={props.ariaLabel}
    >
      <Adult size={24} />
      <div
        className={cx(
          styles.amountContainer,
          props.isPartialTextStyle && styles.amountContainerSmall,
        )}
      >
        <Translate
          id="Top.Number_of_Guests.Guests_Per_Room"
          count={props.adults + props.childrenAges.length}
          data={{ guest_count: props.adults + props.childrenAges.length }}
        />
        &nbsp;•&nbsp;
        <Translate
          id="Top.Number_of_Guests.Room"
          data={{ room_count: props.unit }}
          count={props.unit}
        />
      </div>
    </button>
  );
}

export default TextButton;
