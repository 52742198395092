import dayjs, { Dayjs } from 'dayjs';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import cx from '../../utils/classnames';

import styles from './calendarBody.module.scss';

export type SelectedState =
  | 'selected'
  | 'selectedStartDate'
  | 'selectedEndDate'
  | 'hoveredStartDate'
  | 'hoveredEndDate'
  | 'inSelectedRange'
  | 'unSelected';

export type DayState = 'outDated' | 'invalidMonth' | 'weekend' | 'default';

export type CalendarDayHandle = {
  focus: () => void;
};

type Props = {
  /** Custom style for wrapper */
  className?: string;
  /** Date to display */
  day: Dayjs;
  /** String to define component date state */
  dayState?: DayState;
  /** Custom style for day item */
  itemClassName?: string;
  /** Date format */
  format?: string;
  /** callback function when item is clicked */
  onClick?: (event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => void;
  /** callback function when item is being hovered */
  onHover?: (event: React.MouseEvent | React.FocusEvent, day?: Dayjs) => void;
  /** String to define component highlight state */
  selectedState: SelectedState;
  /** Extra component underneath calendar per day */
  extraComponent?: JSX.Element;
  /** Date Selection Highlight type (default circle) */
  highlightType?: 'circle' | 'full';
  /** Flag to highlight the current date */
  shouldShowCurrentDate?: boolean;
  /** Tabindex of date. 0 for tabbable dates. -1 for dates which can be focused with javascript */
  tabindex: number | undefined;
  /** Id of month label that describes the date for accessibility */
  ariaDescribedBy?: string;
};

const CalendarDay = forwardRef<CalendarDayHandle, Props>(function CalendarDay(
  props: Props,
  ref: React.ForwardedRef<CalendarDayHandle>,
) {
  const {
    className,
    day,
    dayState = 'default',
    itemClassName,
    format = 'D',
    onClick,
    onHover,
    selectedState = 'unSelected',
    extraComponent,
    highlightType = 'circle',
    shouldShowCurrentDate = false,
    tabindex,
    ariaDescribedBy,
    ...rest
  } = props;

  const displayDate = day.format(format);
  const onClickItem = (event: React.MouseEvent) => onClick?.(event, day);
  const onFocusItem = (event: React.FocusEvent) => onHover && onHover(event, day);
  const onMouseOverItem = (event: React.MouseEvent) => onHover && onHover(event, day);
  const onMouseLeaveItem = (event: React.MouseEvent) => onHover && onHover(event, undefined);
  const isCurrentDate = dayjs().isSame(dayjs(day), 'day');

  const shouldSelectionHighlightEntireDay = highlightType === 'full';

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ' /** Space */) {
        event.preventDefault();
        onClick?.(event, day);
      }
    },
    [onClick, day],
  );

  const dayRef = useRef<HTMLTableCellElement>(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        focus: () => dayRef.current && dayRef.current.focus(),
      };
    },
    [],
  );

  return (
    <td
      className={cx(
        styles.itemWrapper,
        styles[selectedState],
        styles[dayState],
        className,
        shouldSelectionHighlightEntireDay && styles[selectedState],
      )}
      onKeyDown={handleKeyDown}
      onClick={onClickItem}
      onMouseOver={onMouseOverItem}
      onMouseLeave={onMouseLeaveItem}
      onFocus={onFocusItem}
      data-testid={`calendarDay-td-${dayState}`}
      tabIndex={tabindex}
      ref={dayRef}
      aria-describedby={ariaDescribedBy}
      data-current-date={isCurrentDate && dayState !== 'invalidMonth' ? 'true' : 'false'}
      {...rest}
    >
      <div className={cx(!shouldSelectionHighlightEntireDay && styles[selectedState])}>
        <div
          className={cx(
            styles.item,
            itemClassName,
            shouldShowCurrentDate && !shouldSelectionHighlightEntireDay && isCurrentDate
              ? styles.today
              : null,
          )}
          data-testid={
            (dayState === 'default' || dayState === 'weekend') && 'calendarDay-selectable-day'
          }
        >
          {displayDate}
        </div>
      </div>
      {!!extraComponent && extraComponent}
    </td>
  );
});

export default React.memo(CalendarDay);
