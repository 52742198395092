import dayjs, { Dayjs } from 'dayjs';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

import { ArrowLeft, ArrowRight } from '@travel/icons/ui';

import useDeviceType from '../../utils/useDeviceType';
import { CalendarBodyHandle } from '../CalendarBody';
import Calendar from './Calendar';

import cx from '../../utils/classnames';
import { generateCalendarMonths } from './helpers';
import { DisplayType, PerDayComponentProps } from './index';

import styles from './datePicker.module.scss';

type Props = {
  /** Custom style for wrapper */
  className: string;
  /** Custom style for the dayItem */
  dayItemClassName?: string;
  /** End date of selected range */
  endDate?: string;
  /** Flag to define whether the CalendarWeekHeader will be shown on calendars or not */
  isShowWeekHeader: boolean;
  /** Flag to define whether the next/prev will be shown on the date-picker or not */
  isShowNavigation: boolean;
  /** Number of displayed months (The first month will be started from referenceDate by default) */
  numberOfMonths: number;
  /** Callback function when calendar day is clicked */
  onClickDayItem: (event: React.MouseEvent | React.KeyboardEvent, day: Dayjs) => void;
  /** Date to define the first calendar */
  referenceDate: string;
  /** Start date of selected range */
  startDate?: string;
  /** Decides using popup or dialog for calendar container */
  type: DisplayType;
  /** week type:
   * 'week': start from Sunday
   * 'isoWeek': start from Monday
   */
  weekType?: 'week' | 'isoWeek';
  /** Array of string to define title of day (normal-ordering) */
  weekdayLabels?: string[];
  /** Array of string to define title of month */
  monthLabels?: string[];
  /** Date to end of reference */
  referenceEndDate?: string;
  dayWrapperClassName?: string;
  /** Extra component to display on calendar per day */
  PerDayComponent?: React.ComponentType<PerDayComponentProps>;
  /** Custom Height for Calendar of each month */
  customCalendarHeight?: number;
  /** Custom style for each Calendar component */
  calendarWrapperClassName?: string;
  /** Custom format for display */
  labelDateFormat?: string;
  /** Custom style for wrapper of calendar month labels */
  calendarLabelClassName?: string;
  /** Custom style for wrapper of calendar week headers */
  calendarWeekHeaderClassName?: string;
  /** Custom style for calendar body wrapper (table) */
  calendarBodyClass?: string;
  /** Date Selection Highlight type (default circle) */
  highlightType?: 'circle' | 'full';
  /** Flag to highlight the current date */
  shouldShowCurrentDate?: boolean;
  /** Flag to hide any day hovering */
  shouldDisableDayHover?: boolean;
  /** Custom style for navigation arrows */
  navigationIconClassName?: string;
  /** Size in px of navigation arrows */
  navigationIconSize?: number;
  /** disable month navigation arrows from the calendar */
  isMonthNavigationDisabled?: boolean;
  /** onClick callback for clicking next / prev month button */
  onClickNavigationCallBack?: (months: string[]) => void;
};

const ICON_SIZE = 24;

function DatePickerBody(props: Props) {
  const {
    className,
    dayItemClassName,
    numberOfMonths,
    referenceDate,
    endDate,
    startDate,
    onClickDayItem,
    isShowNavigation,
    isShowWeekHeader,
    type,
    weekType,
    weekdayLabels,
    monthLabels,
    referenceEndDate,
    dayWrapperClassName,
    PerDayComponent,
    customCalendarHeight = 300,
    labelDateFormat,
    calendarLabelClassName,
    calendarWrapperClassName,
    calendarWeekHeaderClassName,
    calendarBodyClass,
    highlightType = 'circle',
    shouldShowCurrentDate,
    shouldDisableDayHover = false,
    navigationIconClassName,
    navigationIconSize = ICON_SIZE,
    isMonthNavigationDisabled = false,
    onClickNavigationCallBack,
  } = props;

  // hover state and handler
  const isPC = useDeviceType() === 'pc';
  const [hoveredDate, setHoveredDate] = useState<string>('');
  const onHoverDayItem = useCallback((_event: React.MouseEvent | React.FocusEvent, day?: Dayjs) => {
    setHoveredDate(prevState => {
      const nextHoveredDate = day?.format('YYYY-MM-DD');
      if (nextHoveredDate && nextHoveredDate !== prevState) {
        return nextHoveredDate;
      }

      return prevState;
    });
  }, []);

  // next/prev state and handler
  // CalendarDate will be used to determine what the first calendar should be.
  const [startCalendarDate, setStartCalendarDate] = useState(
    type === 'popup' ? startDate || referenceDate : referenceDate,
  );
  const isInvalidPrevMonth = dayjs(startCalendarDate).isSame(referenceDate, 'month');
  const isInvalidNextMonth =
    referenceEndDate && dayjs(startCalendarDate).isSame(referenceEndDate, 'month');

  const handleCalendarMonthChange = useRef<'' | 'next' | 'prev'>('');
  // generate months to be rendered
  const calendarMonths = useMemo(() => generateCalendarMonths(startCalendarDate, numberOfMonths), [
    startCalendarDate,
    numberOfMonths,
  ]);

  const onClickNext = useCallback(() => {
    setStartCalendarDate(prevState =>
      dayjs(prevState)
        .add(1, 'month')
        .toString(),
    );
    const nextStartDate = dayjs(startCalendarDate)
      .add(1, 'month')
      .toString();
    onClickNavigationCallBack?.(generateCalendarMonths(nextStartDate, numberOfMonths));
  }, [onClickNavigationCallBack]);
  const onClickPrev = useCallback(() => {
    setStartCalendarDate(prevState =>
      dayjs(prevState)
        .subtract(1, 'month')
        .toString(),
    );
    const prevStartDate = dayjs(startCalendarDate)
      .subtract(1, 'month')
      .toString();
    onClickNavigationCallBack?.(generateCalendarMonths(prevStartDate, numberOfMonths));
  }, [onClickNavigationCallBack]);

  const onKeyDown = useCallback(
    (_event: React.KeyboardEvent) => {
      let monthIndex = 0;
      const hoveredDay = dayjs(hoveredDate);
      calendarMonths.forEach((calendarMonth, index) => {
        if (dayjs(calendarMonth).month() === hoveredDay.month()) {
          monthIndex = index;
        }
      });
      if (_event.key === 'ArrowUp') {
        _event.preventDefault();
        calendarRefs.current[monthIndex].focusUp();
      } else if (_event.key === 'ArrowRight') {
        _event.preventDefault();
        if (hoveredDay.date() === hoveredDay.daysInMonth()) {
          if (monthIndex < calendarMonths.length - 1) {
            return calendarRefs.current[monthIndex + 1].focusFirst();
          } else {
            onClickNext();
            handleCalendarMonthChange.current = 'next';
          }
        }
        calendarRefs.current[monthIndex].focusNext();
      } else if (_event.key === 'ArrowDown') {
        _event.preventDefault();
        calendarRefs.current[monthIndex].focusDown();
      } else if (_event.key === 'ArrowLeft') {
        _event.preventDefault();
        if (dayjs(hoveredDate).date() === 1) {
          if (monthIndex > 0) {
            return calendarRefs.current[monthIndex - 1].focusLast();
          } else {
            onClickPrev();
            handleCalendarMonthChange.current = 'prev';
          }
        }
        calendarRefs.current[monthIndex].focusPrevious();
      }
    },
    [hoveredDate, calendarMonths, onClickPrev, onClickNext],
  );

  const onKeyDownPrev = useCallback(
    (_event: React.KeyboardEvent) => {
      if (_event.key === 'Enter' || _event.key === ' ' /** Space */) {
        _event.preventDefault();
        !isInvalidPrevMonth && onClickPrev();
      }
    },
    [onClickPrev, isInvalidPrevMonth],
  );

  const onKeyDownNext = useCallback(
    (_event: React.KeyboardEvent) => {
      if (_event.key === 'Enter' || _event.key === ' ' /** Space */) {
        _event.preventDefault();
        !isInvalidNextMonth && onClickNext();
      }
    },
    [onClickNext, isInvalidNextMonth],
  );

  useEffect(() => {
    if (handleCalendarMonthChange.current === 'next') {
      calendarRefs.current[1].focusFirst();
    } else if (handleCalendarMonthChange.current === 'prev') {
      calendarRefs.current[calendarRefs.current.length - 2].focusLast();
    } else {
      setHoveredDate('');
    }
    handleCalendarMonthChange.current = '';
  }, [startCalendarDate]);

  // default calendar props
  const defaultCalendarProps = {
    startDate,
    endDate,
    hoveredDate,
    onHoverDayItem: isPC && !shouldDisableDayHover ? onHoverDayItem : undefined,
    onClickDayItem,
    isShowWeekHeader,
    weekType,
    weekdayLabels,
    monthLabels,
    dayWrapperClassName,
    PerDayComponent,
    labelDateFormat,
    calendarLabelClassName,
    calendarWeekHeaderClassName,
    calendarBodyClass,
    highlightType,
    isMonthNavigationDisabled,
    dayItemClassName,
  };
  const calendarRefs = useRef<CalendarBodyHandle[]>([]);

  return (
    <div onKeyDown={onKeyDown} className={cx(className, styles.datePicker)}>
      {type === 'dialog' && (
        // 1000px should be high enough for all of the supported mobiles
        <List
          itemCount={calendarMonths.length}
          height={1000}
          width="100%"
          itemSize={customCalendarHeight}
        >
          {({ index, style }) => (
            <Calendar
              style={style}
              className={calendarWrapperClassName}
              key={calendarMonths[index]}
              referenceDate={referenceDate}
              referenceEndDate={referenceEndDate}
              shouldShowCurrentDate={shouldShowCurrentDate}
              calendarDate={calendarMonths[index]}
              ref={ref => (calendarRefs.current[index] = ref!)}
              {...defaultCalendarProps}
            />
          )}
        </List>
      )}
      {type === 'popup' && (
        <>
          {calendarMonths.map((month, index) => (
            <Calendar
              key={month}
              className={calendarWrapperClassName}
              referenceDate={referenceDate}
              referenceEndDate={referenceEndDate}
              shouldShowCurrentDate={shouldShowCurrentDate}
              calendarDate={month}
              ref={ref => (calendarRefs.current[index] = ref!)}
              {...defaultCalendarProps}
            />
          ))}
        </>
      )}
      {isShowNavigation && (
        <>
          <ArrowLeft
            className={cx(
              styles.icon,
              styles.left,
              (isMonthNavigationDisabled || isInvalidPrevMonth) && styles.invalid,
              navigationIconClassName,
            )}
            role="button"
            onClick={onClickPrev}
            onKeyDown={onKeyDownPrev}
            size={navigationIconSize}
            tabIndex={0}
          />
          <ArrowRight
            className={cx(
              styles.icon,
              styles.right,
              (isMonthNavigationDisabled || isInvalidNextMonth) && styles.invalid,
              navigationIconClassName,
            )}
            role="button"
            onClick={onClickNext}
            onKeyDown={onKeyDownNext}
            size={navigationIconSize}
            tabIndex={0}
          />
        </>
      )}
    </div>
  );
}

DatePickerBody.defaultProps = {
  className: '',
  isActive: false,
  isShowWeekHeader: true,
  isShowNavigation: false,
  numberOfMonths: 1,
};

export default DatePickerBody;
