import React from 'react';

import VacancyCalendar, { Props, VacancyCalendarRef } from './';

const VacancyCalendarWithRef = React.memo(
  React.forwardRef((props: Props, ref: React.Ref<VacancyCalendarRef>) => (
    <VacancyCalendar {...props} innerRef={ref} />
  )),
);

export default VacancyCalendarWithRef;
