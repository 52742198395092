function debounce<T extends Function>(
  func: T,
  delay: number,
  { leading }: { leading?: boolean } = {},
) {
  let timerId: ReturnType<typeof setTimeout>;

  return (...args: Array<any>) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
}

export default debounce;
