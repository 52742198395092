import React, {
  ChangeEvent,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import Adult from '@travel/icons/service/Adult';
import { Info } from '@travel/icons/ui';
import AdultForm from '@travel/traveler-core/components/AmountInput/AdultForm';
import FormHiddenInputs from '@travel/traveler-core/components/AmountInput/FormHiddenInputs';
import GreenStyleButton from '@travel/traveler-core/components/AmountInput/GreenStyleButton';
import {
  useNumberArrayIncrementHandler,
  useNumberIncrementHandler,
  ValidateFunction,
} from '@travel/traveler-core/components/AmountInput/hooks';
import TextButton from '@travel/traveler-core/components/AmountInput/TextButton';
import UnitForm from '@travel/traveler-core/components/AmountInput/UnitForm';
import {
  DEFAULT_ADULT,
  DEFAULT_CHILDREN_AGES,
  DEFAULT_ROOMS,
} from '@travel/traveler-core/constants';
import {
  useBlurWrapper,
  useDeviceType,
  useDialogHandler,
  useKeyboard,
  useTranslation,
} from '@travel/traveler-core/hooks';
import { FlatButton, IconText, Incrementer, Popup, SelectBox } from '@travel/ui';
import { cx, isNotEmptyArray } from '@travel/utils';

import CouponSearchError from 'components/CouponSearchError';
import DataLayer from 'components/DataLayer';
import Dialog from 'components/Dialog';

import { getLimitationsForCurrentMarket } from 'store/markets/selectors';

import { RAT_PAGE_TYPE_OBJ } from 'constants/ratAnalytics';
import { Translate } from 'core/translate';
import useEffectExceptForFirstRendering from 'hooks/useEffectExceptForFirstRendering';

import styles from './amountInput.module.scss';

export type AmountValue = {
  rooms?: number;
  adults?: number;
  childrenAges?: Array<number>;
};

export type AmountInputRef = {
  getAmount: () => Required<AmountValue>;
  setAmount: (amount: AmountValue) => void;
  focus: () => void;
} | null;

export type Props = {
  className?: string;
  /** String of custom popup classname */
  popupWrapperClassName?: string;
  /** String of custom dialogContent classname */
  dialogContentClassName?: string;
  /** on what parent page this component lives */
  onPage:
    | 'top'
    | 'providerListDated'
    | 'providerListUndated'
    | 'providerInfoDated'
    | 'providerInfoUndated'
    | 'couponSearch'
    | 'area'
    | 'vacancyCalendar';
  /** Initial value of room number */
  defaultRooms?: number;
  /** Initial value of adult number */
  defaultAdults?: number;
  /** Initial value of children ages */
  defaultChildrenAges?: number[];
  /** Callback when the ok button on Dialog (sp/tl) has been clicked */
  onClickOkButton?: (noOfUnits: number, adults: number, childrenAges?: number[]) => void;
  /** Validation callback when adults state changed */
  onValidateAdults?: ValidateFunction;
  /** Validation callback when children state changed */
  onValidateGuests?: ValidateFunction;
  /** Validation callback when number of units state changed */
  onValidateNoOfUnits?: ValidateFunction;
  /** TEQB-14696: Add a boolean prop to ascertain whether or not dates are set to disable certain values  */
  isDatesSet?: boolean;
  /** inner Ref */
  innerRef?: Ref<AmountInputRef>;
  /** on close click callback */
  onCloseClick?: (rooms?: number, adults?: number, childrenAges?: number[]) => void;
  /** callback when values are changed */
  onValuesChange?: (values: AmountValue) => void;
  /** Flag to enable animation when Dialog mounted */
  hasAnimationDialog?: boolean;
};

// this is max-height of pc content, please update the value accordingly with any changes
const PC_CONTENT_MAX_HEIGHT = 450;

const ITEM_COUNT_FEEDBACK_ID = 'item-count-feedback-id';
const CHILD_COUNT_FEEDBACK_ID = 'child-count-feedback-id';

function AmountInput(props: Props) {
  const deviceType = useDeviceType();
  const isSP = deviceType === 'sp';
  const isPc = deviceType === 'pc';

  const buttonLabel = useTranslation({ id: 'Traveler_Common.Alt.Number_Of_Guests_And_Rooms' });

  const { isOpen, onOpen, onClose, onToggle } = useDialogHandler(false);

  const isShowUndatedInfoMessage = !props.isDatesSet;

  const { pageType, siteSection, parentName } = RAT_PAGE_TYPE_OBJ[props.onPage];

  const limitations = useSelector(getLimitationsForCurrentMarket);
  const maximumChildAge = limitations?.searchLimitation?.maximumChildAge || 10;
  const maximumNumberOfUnit = limitations?.searchLimitation?.maximumNumberOfUnit || 10;
  const maximumNumberOfAdult = limitations?.searchLimitation?.maximumNumberOfAdult || 10;
  const maximumNumberOfChild = limitations?.searchLimitation?.maximumNumberOfChild || 10;

  const ageOptions = useMemo(
    () =>
      Array(maximumChildAge + 1)
        .fill(1)
        .map((_, i) => {
          const ageStr = String(i);
          return { text: ageStr, value: ageStr };
        }),
    [maximumChildAge],
  );

  const onCloseClick = () => {
    onClose();
    props.onCloseClick?.(rooms.counter, adults.counter, childrenAges.counter);
  };

  const childrenAges = useNumberArrayIncrementHandler(
    isShowUndatedInfoMessage
      ? DEFAULT_CHILDREN_AGES
      : props.defaultChildrenAges || DEFAULT_CHILDREN_AGES,
    0,
    maximumNumberOfChild,
    isShowUndatedInfoMessage,
  );
  const rooms = useNumberIncrementHandler(
    isShowUndatedInfoMessage ? DEFAULT_ROOMS : props.defaultRooms || DEFAULT_ROOMS,
    props.onValidateNoOfUnits,
    1,
    maximumNumberOfUnit,
    isShowUndatedInfoMessage,
  );
  const adults = useNumberIncrementHandler(
    props.defaultAdults || DEFAULT_ADULT,
    props.onValidateAdults,
    1,
    maximumNumberOfAdult,
  );

  let wrapperRef = useRef<HTMLDivElement | null>(null);
  let contentEleRef = useRef<HTMLDivElement | null>(null);
  const [isApplyBoxShadow, setIsApplyBoxShadow] = useState<boolean>(false);

  // These prevXXX refs always keep the previous value when a user opens AmountInput.
  // They are needed because when a user clicks the close button, we want to reset the value
  const prevRoomCounter = useRef(props.defaultRooms || DEFAULT_ROOMS);
  const prevAdultCounter = useRef(props.defaultAdults || DEFAULT_ADULT);
  const prevChildrenAges = useRef(props.defaultChildrenAges || DEFAULT_CHILDREN_AGES);

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const isClick = useRef<boolean>(false);
  const dialogRef = useRef<{
    contains: (node: Node) => boolean;
  } | null>(null);

  const resetPrevValue = useCallback(() => {
    prevRoomCounter.current = rooms.counter;
    prevAdultCounter.current = adults.counter;
    prevChildrenAges.current = childrenAges.counter;
  }, [adults.counter, childrenAges.counter, rooms.counter]);

  // to update the popup design based on content height (pc)
  const measuredContentRef = (node: HTMLDivElement) => {
    if (node !== null) {
      // get initial height
      contentEleRef.current = node;
      setIsApplyBoxShadow(node.offsetHeight >= PC_CONTENT_MAX_HEIGHT);
    }
  };

  useImperativeHandle(props.innerRef, () => ({
    setAmount: newAmount => {
      if (newAmount.rooms) {
        rooms.set(newAmount.rooms);
      }
      if (newAmount.adults) {
        adults.set(newAmount.adults);
      }
      if (newAmount.childrenAges) {
        childrenAges.set(newAmount.childrenAges);
      }
    },
    getAmount: () => {
      return { adults: adults.counter, rooms: rooms.counter, childrenAges: childrenAges.counter };
    },
    focus: () => {
      wrapperRef.current?.focus();
    },
  }));

  useEffect(() => {
    if (isPc && contentEleRef.current) {
      const contentHeight = contentEleRef.current?.getBoundingClientRect().height || 0;
      setIsApplyBoxShadow(contentHeight >= PC_CONTENT_MAX_HEIGHT);
    }
  }, [childrenAges, isPc]);

  useEffect(() => {
    if (isShowUndatedInfoMessage) {
      childrenAges.set(DEFAULT_CHILDREN_AGES);
      rooms.set(DEFAULT_ROOMS);

      resetPrevValue();
    }
  }, [isShowUndatedInfoMessage, resetPrevValue, childrenAges, rooms]);

  useEffectExceptForFirstRendering(() => {
    if (props.onValuesChange) {
      props.onValuesChange({
        adults: adults.counter,
        childrenAges: childrenAges.counter,
        rooms: rooms.counter,
      });
    }
  }, [adults.counter, childrenAges.counter, rooms.counter]);

  const onFocus = useCallback(
    (event: React.FocusEvent) => {
      if (
        event.target instanceof HTMLButtonElement &&
        event.currentTarget.contains(event.target) &&
        !isOpen
      ) {
        // Kind of hacky but lets us capture the button without passing a ref to each potential button component
        buttonRef.current = event.target;
        if (!isClick.current) {
          onOpen();
        }
        isClick.current = false;
      }
    },
    [onOpen, isOpen],
  );
  const handleBlur = useCallback(
    (event: React.FocusEvent) => {
      if (isPc && !dialogRef?.current?.contains(event.relatedTarget as Node)) {
        isClick.current = false;
        onClose();
      }
    },
    [isPc, onClose],
  );
  const onBlur = useBlurWrapper(handleBlur);
  const handleKeyDown = useMemo(() => {
    return {
      Escape: (event: React.KeyboardEvent) => {
        if (isOpen) {
          event.stopPropagation();
          buttonRef.current?.focus();
          onClose();
        }
      },
    };
  }, [onClose, isOpen]);
  const onKeyDown = useKeyboard(handleKeyDown);
  const onPointerDown = useCallback(() => {
    isClick.current = true;
  }, []);

  const onChangeChildrenAge = (event: ChangeEvent<HTMLSelectElement>, index: number) => {
    childrenAges.onItemUpdate(Number(event.target.value), index);
  };

  // page
  const isCouponSearchPage = props.onPage === 'couponSearch';
  const unitsForm = <UnitForm ariaDescribedBy={ITEM_COUNT_FEEDBACK_ID} {...rooms} />;
  const adultsForm = <AdultForm {...adults} />;

  const isOnTop = props.onPage === 'top';
  const isOnArea = props.onPage === 'area';
  const isPartialTextStyle = (isOnTop || isOnArea) && isSP;
  const isFullTextStyle = isOnTop || isOnArea;
  const isSummaryGuestStyle = isCouponSearchPage;
  const isProviderListPage =
    props.onPage === 'providerListUndated' || props.onPage === 'providerListDated';
  const isProviderInfoPage =
    props.onPage === 'providerInfoUndated' || props.onPage === 'providerInfoDated';
  const isVacancyCalendar = props.onPage === 'vacancyCalendar';
  const onPageClassName = isProviderListPage
    ? 'providerList'
    : isProviderInfoPage
    ? 'providerInfo'
    : props.onPage;
  const isConfirmButtonDisabled = !(
    !isCouponSearchPage || !props.onValidateGuests?.(adults.counter + childrenAges.counter.length)
  );

  const dialogContent = (
    <div
      aria-live="off"
      data-testid="amountInput-dialog-content"
      className={props.dialogContentClassName}
    >
      <div className={styles.contentWrapper} ref={measuredContentRef}>
        <div className={styles.amountFormContainer}>
          <Translate id="Top.Modals.Rooms_Guests.Room_Title" className={styles.amountName} />
          {unitsForm}
          {isShowUndatedInfoMessage ? (
            <InfoText
              id={ITEM_COUNT_FEEDBACK_ID}
              translateId="Traveler_Common.Undated.Item_Count_Feedback"
            />
          ) : null}
        </div>
        <div className={styles.amountFormContainer}>
          <Translate id="Top.Modals.Rooms_Guests.Guest_Title" className={styles.amountName} />
          {adultsForm}
          <div className={styles.amountForm}>
            <Translate id="Top.Modals.Rooms_Guests.Children" className={styles.amountLabel} />
            <Incrementer
              value={childrenAges.counter.length}
              isLeftDisabled={childrenAges.isLeftDisabled}
              isRightDisabled={childrenAges.isRightDisabled}
              onIncrease={childrenAges.onIncrease}
              onDecrease={childrenAges.onDecrease}
              decrementAriaLabel={'decrement children'}
              incrementAriaLabel={'increment children'}
              data-testid="amountInput-incrementer-childrenAges"
              ariaDescribedBy={CHILD_COUNT_FEEDBACK_ID}
            />
          </div>
          {isShowUndatedInfoMessage ? (
            <InfoText
              id={CHILD_COUNT_FEEDBACK_ID}
              translateId="Traveler_Common.Undated.Child_Count_Feedback"
            />
          ) : null}
          {props.onValidateGuests && (
            <CouponSearchError
              {...props.onValidateGuests(adults.counter + childrenAges.counter.length)}
            />
          )}
        </div>
        {isNotEmptyArray(childrenAges.counter) && (
          <div className={styles.amountFormContainer}>
            {childrenAges.counter.map((item, index) => (
              <div key={index} className={styles.ageFormContainer}>
                <Translate
                  id="Top.Modals.Rooms_Guests.Age_of_child"
                  data={{ counter: index + 1 }}
                  exist
                  className={styles.label}
                />
                <SelectBox
                  name="default"
                  options={ageOptions}
                  className={styles.ageSelectBox}
                  defaultValue={`${item}`}
                  onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                    onChangeChildrenAge(event, index)
                  }
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {isPc && !isVacancyCalendar ? (
        <div className={cx(styles.amountOk, isApplyBoxShadow && styles.withBoxShadow)}>
          <FlatButton
            classType="list"
            onClick={onCloseClick}
            isSmallerButtonByDefault
            className={styles.amountOkButton}
            data-testid="amountInput-confirm-button"
            isDisabled={isConfirmButtonDisabled}
          >
            {isProviderInfoPage ? (
              <Translate id="Provider_Info.Update" />
            ) : (
              <Translate id="Traveler_Common.OK" />
            )}
          </FlatButton>
        </div>
      ) : null}
    </div>
  );

  const guestCount = adults.counter + childrenAges.counter.length;

  const guestCountText = (
    <Translate
      id="Top.Number_of_Guests.Guests_Per_Room"
      count={guestCount}
      data={{ guest_count: guestCount }}
    />
  );
  const roomCountText = (
    <Translate
      id="Top.Number_of_Guests.Room"
      data={{ room_count: rooms.counter }}
      count={rooms.counter}
    />
  );

  return (
    <>
      {isVacancyCalendar && isPc ? (
        dialogContent
      ) : (
        <div
          data-testid="amountInput-div-wrapper"
          className={cx(
            props.className,
            styles.amountInputContainer,
            (isOnArea || isOnTop) && styles.topAreaAmountWrapper,
            isVacancyCalendar && styles.vacancyCalendarAmountWrapper,
          )}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          onPointerDown={onPointerDown}
          ref={wrapperRef}
          role="toolbar"
          tabIndex={0}
        >
          {isFullTextStyle && (
            <TextButton
              className={styles.topAreaAmountButton}
              onClick={onToggle}
              unit={rooms.counter}
              adults={adults.counter}
              childrenAges={childrenAges.counter}
              isPartialTextStyle={isPartialTextStyle}
              ariaLabel={buttonLabel}
            />
          )}

          {isProviderListPage || isProviderInfoPage || (isVacancyCalendar && !isPc) ? (
            <FlatButton
              onClick={onToggle}
              className={cx(
                styles.providerListButton,
                isProviderInfoPage && styles.providerInfoButton,
              )}
              classType="secondary"
              icon={<Adult size={isSP ? 16 : 24} color="default" />}
              type="button"
              isSmallerButtonByDefault
              data-testid="amountInput-button"
              ariaLabel={buttonLabel}
              labelClassName={styles.providerListButtonLabel}
            >
              {guestCountText}
              &nbsp;•&nbsp;
              {roomCountText}
            </FlatButton>
          ) : null}

          {isSummaryGuestStyle && (
            <button
              type="button"
              className={styles.formButton}
              onClick={onToggle}
              data-testid="amountInput-summaryGuestStyle-button"
              aria-label={buttonLabel}
            >
              <Adult size={isCouponSearchPage && isSP ? 16 : 24} />
              <div className={styles.amountContainer}>
                {guestCountText}
                &nbsp;•&nbsp;
                {roomCountText}
              </div>
            </button>
          )}
          {isOpen &&
            (isPc ? (
              <Popup
                className={cx(
                  props.popupWrapperClassName,
                  styles.popupContainer,
                  styles[onPageClassName + 'Position'],
                  styles.popup,
                )}
                isOpen={isOpen}
                onClose={onClose}
                data-testid="amountInput-capacity-popup"
              >
                {dialogContent}
              </Popup>
            ) : (
              <>
                <Dialog
                  className={styles.dialogWrapper}
                  contentClassName={styles.dialogContent}
                  headerClassName={styles.dialogHeader}
                  actionClassName={styles.dialogAction}
                  isDisplayedOnDialog={isCouponSearchPage}
                  title={<Translate id="Top.Modals.Rooms_Guests.Title" />}
                  isOpen={isOpen}
                  buttonLabelPrimary={
                    isProviderInfoPage ? (
                      <Translate id="Provider_Info.Update" />
                    ) : (
                      <Translate id="Traveler_Common.OK" />
                    )
                  }
                  isDisabledPrimary={isConfirmButtonDisabled}
                  isUseContentFlexBox={false}
                  onClickPrimary={() => {
                    resetPrevValue();
                    onClose();
                    props.onClickOkButton?.(rooms.counter, adults.counter, childrenAges.counter);
                    isVacancyCalendar &&
                      props.onCloseClick?.(rooms.counter, adults.counter, childrenAges.counter);
                  }}
                  onClose={() => {
                    rooms.set(prevRoomCounter.current);
                    adults.set(prevAdultCounter.current);
                    childrenAges.set(prevChildrenAges.current);
                    onClose();
                  }}
                  hasEnableBack={false}
                  contentRef={dialogRef}
                  hasAnimation={props?.hasAnimationDialog}
                >
                  {dialogContent}
                </Dialog>
                <DataLayer
                  pageName={`${parentName}:rooms_and_guests_number_picker_popup`}
                  pageType={pageType}
                  siteSection={siteSection}
                />
              </>
            ))}

          <FormHiddenInputs
            adults={adults.counter}
            unit={rooms.counter}
            childrenAges={childrenAges.counter}
          />
        </div>
      )}
    </>
  );
}

type InfoTextProps = {
  translateId: string;
  id?: string;
};

function InfoText({ translateId, id }: InfoTextProps) {
  return (
    <IconText
      id={id}
      text={<Translate id={translateId} />}
      icon={<Info size={16} className={styles.infoIcon} />}
      className={styles.infoWrapper}
      spaceBetween={4}
      data-testid={`amountInput-room-errorMessage-${translateId}`}
    />
  );
}

export default AmountInput;
