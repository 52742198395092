import React from 'react';

import CouponSearchErrorCore, { Props } from '@travel/traveler-core/components/CouponSearchError';

function CouponSearchError(props: Props) {
  if (!props.message) {
    return null;
  }

  return <CouponSearchErrorCore {...props} />;
}

export default CouponSearchError;
