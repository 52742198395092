import { ReactNode } from 'react';

import { Adult, Bed } from '@travel/icons/service';
import { Calendar } from '@travel/icons/ui';
import usePriceLabel from '@travel/traveler-core/hooks/usePriceLabel';
import { IconText } from '@travel/ui';

import styles from './priceFor.module.scss';

type Props = {
  rooms: number;
  adults: number;
  childrenAges: number[];
  startDate?: string;
  endDate?: string;
  title?: ReactNode;
  shouldShowIcon?: boolean;
};

function PriceFor({
  rooms,
  adults,
  childrenAges,
  startDate,
  endDate,
  title,
  shouldShowIcon = true,
}: Props) {
  const { guestLabel, nightLabel, roomsLabelText } = usePriceLabel({
    noOfUnits: rooms.toString(),
    adults: adults.toString(),
    childrenAges: childrenAges.toString(),
    startDate: startDate,
    endDate: endDate,
  });

  return (
    <>
      {!shouldShowIcon ? (
        <>
          {<>{nightLabel} | </>}
          {roomsLabelText && <>{roomsLabelText} | </>}
          {guestLabel}
        </>
      ) : (
        <div className={styles.priceFor} data-testid="priceFor-wrapper">
          {title}
          <IconText
            text={nightLabel}
            icon={<Calendar size={16} color="sonicSilver" />}
            spaceBetween={4}
          />
          &nbsp;|&nbsp;
          <IconText
            text={roomsLabelText}
            icon={<Bed size={16} color="sonicSilver" />}
            spaceBetween={4}
          />
          &nbsp;|&nbsp;
          <IconText
            text={guestLabel}
            icon={<Adult size={16} color="sonicSilver" />}
            spaceBetween={4}
          />
        </div>
      )}
    </>
  );
}

export default PriceFor;
