import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import useDeviceType, { DESKTOP_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';

import {
  clearErrorMessage,
  fetchMoreVacancyCalendar,
  fetchVacancyCalendar,
  fetchVacancyCalendarDetails,
} from 'store/vacancyCalendar/actions';

import { generateMonthArray } from 'utils/date';

const useFetchVacancyCalendarData = () => {
  const dispatch = useDispatch();
  const isPC = useDeviceType() === DESKTOP_SCREEN;

  const onClickFetchVacancyCalendar = (
    adults: number,
    children: number[],
    rooms: number,
    startDate: string = '',
    endDate: string = '',
    months: string[],
    itemRatePlanId: string = '',
    couponId?: string,
  ) => {
    const request = {
      itemRatePlanId: itemRatePlanId,
      adults: adults,
      children: children,
      rooms: rooms,
    };
    dispatch(clearErrorMessage());
    if (!isPC) {
      // for SP / TL we fetch all availability due to the calendar style
      const requestMonths = generateMonthArray();
      dispatch(fetchVacancyCalendar({ ...request, months: requestMonths }));
    } else {
      dispatch(fetchVacancyCalendar({ ...request, months: months }));
    }
    onClickFetchVacancyCalendarPrice(
      adults,
      children,
      rooms,
      startDate,
      endDate,
      itemRatePlanId,
      couponId,
    );
  };

  const onClickFetchVacancyCalendarPrice = (
    adults: number,
    children: number[],
    rooms: number,
    startDate?: string,
    endDate?: string,
    itemRatePlanId: string = '',
    couponId?: string,
  ) => {
    if (startDate && endDate) {
      dispatch(
        fetchVacancyCalendarDetails({
          itemRatePlanId: itemRatePlanId,
          adults: adults,
          children: children,
          rooms: rooms,
          checkInDate: dayjs(startDate).format('YYYY-MM-DD'),
          checkOutDate: dayjs(endDate).format('YYYY-MM-DD'),
          ...(couponId && {
            coupon: {
              id: couponId,
            },
          }),
        }),
      );
    }
  };

  const onClickFetchMoreVacancyCalendar = (
    adults: number,
    children: number[],
    rooms: number,
    months: string[],
    itemRatePlanId: string = '',
  ) => {
    dispatch(
      fetchMoreVacancyCalendar({
        itemRatePlanId: itemRatePlanId,
        adults: adults,
        children: children,
        rooms: rooms,
        months: months,
      }),
    );
  };

  return {
    onClickFetchVacancyCalendar,
    onClickFetchVacancyCalendarPrice,
    onClickFetchMoreVacancyCalendar,
  };
};

export default useFetchVacancyCalendarData;
