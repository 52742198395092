import React from 'react';

import LocaleDatePickerCore, { Props } from '@travel/traveler-core/components/LocaleDatePicker';
import { useTranslation } from '@travel/traveler-core/hooks';

function LocaleDatePicker(
  props: Props & {
    className?: string;
    /** Flag to enable animation when Dialog mounted */
    hasAnimationDialog?: boolean;
  },
) {
  const startDateButtonLabel = useTranslation({ id: 'Traveler_Common.Alt.Check_In_Date' });
  const endDateButtonlabel = useTranslation({ id: 'Traveler_Common.Alt.Check_Out_Date' });
  return (
    <LocaleDatePickerCore
      startDateButtonAriaLabel={startDateButtonLabel}
      endDateButtonAriaLabel={endDateButtonlabel}
      {...props}
    />
  );
}

export default LocaleDatePicker;
