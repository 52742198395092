import React from 'react';

import { Calendar } from '@travel/icons/ui';
import { useTranslation } from '@travel/traveler-core/hooks';
import { FlatButton } from '@travel/ui';
import { cx } from '@travel/utils';

import styles from './smallDateButton.module.scss';

type Props = {
  className?: string;
  /** Date string of start date */
  startDate?: string;
  /** Date string of end date */
  endDate?: string;
  /** Callback to be called when button is clicked */
  onClick: () => void;
  /** Callback to be called when button is focused */
  onFocus?: (event: React.FocusEvent) => void;
  /** Date component to specify custom format */
  DateComponent: React.ComponentType<{ value: string }>;
  /** Button ref */
  buttonRef?: React.RefObject<HTMLButtonElement>;
  /** ARIA label for button */
  ariaLabel?: string;
};

function SmallDateButton(props: Props, ref: React.RefObject<HTMLButtonElement>) {
  const {
    className,
    startDate,
    endDate,
    onClick,
    onFocus,
    DateComponent,
    buttonRef,
    ariaLabel,
  } = props;
  let displayText: React.ReactNode = useTranslation({ id: 'Top.Check_In_Out' });

  if (startDate && endDate) {
    displayText = (
      <>
        <DateComponent value={startDate} />
        &nbsp;-&nbsp;
        <DateComponent value={endDate} />
      </>
    );
  }

  return (
    <FlatButton
      isSmallerButtonByDefault
      icon={<Calendar size={24} color="default" />}
      onClick={onClick}
      onFocus={onFocus}
      className={cx(styles.button, startDate && endDate ? '' : styles.placeholder, className)}
      data-testid="smallDateButton-button"
      innerRef={buttonRef}
      ariaLabel={ariaLabel}
    >
      {displayText}
    </FlatButton>
  );
}

export default SmallDateButton;
