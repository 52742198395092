import React, { useCallback, useState } from 'react';

import { Status as FeedbackStatus } from '../CouponSearchError';

import { EMPTY_ARRAY } from '../../constants';

export type ValidateFunction = (counter: number) => ValidationResponse;
export type ValidationResponse = {
  message: React.ReactNode;
  status?: FeedbackStatus;
} | null;

export const useNumberIncrementHandler = (
  initial: number,
  validation?: ValidateFunction,
  min: number = 1,
  max: number = 10,
  isShowUndatedInfoMessage: boolean = false,
) => {
  const [counter, setCounter] = useState<number>(initial < max ? initial : max);
  const isLeftDisabled = counter <= min || isShowUndatedInfoMessage;
  const isRightDisabled = counter >= max || isShowUndatedInfoMessage;

  const onDecrease = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!isLeftDisabled) {
        setCounter(prevState => (prevState - 1 >= min ? prevState - 1 : prevState));
      }
    },
    [isLeftDisabled, min],
  );

  const onIncrease = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (!isRightDisabled) {
        setCounter(prevState => (prevState + 1 <= max ? prevState + 1 : prevState));
      }
    },
    [isRightDisabled, max],
  );

  return {
    counter,
    isLeftDisabled,
    isRightDisabled,
    onDecrease,
    onIncrease,
    set: setCounter,
    validation: validation?.(counter),
  };
};

export const useNumberArrayIncrementHandler = (
  initial: Array<number> = EMPTY_ARRAY,
  min: number = 0,
  max: number = 10,
  isShowUndatedInfoMessage: boolean = false,
) => {
  const [counter, setCounter] = useState<Array<number>>(initial);
  const isLeftDisabled = counter.length <= min || isShowUndatedInfoMessage;
  const isRightDisabled = counter.length >= max || isShowUndatedInfoMessage;

  const onDecrease = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (isLeftDisabled) return;

      setCounter(prev => {
        if (prev.length - 1 >= min) {
          const cloneCounter = prev.slice();
          cloneCounter.pop();
          return cloneCounter;
        } else {
          return prev;
        }
      });
    },
    [isLeftDisabled, min],
  );

  const onIncrease = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      if (isRightDisabled) return;

      setCounter(prev => {
        if (prev.length + 1 <= max) {
          return [...prev, 0];
        } else {
          return prev;
        }
      });
    },
    [isRightDisabled, max],
  );

  const onItemUpdate = useCallback((value: number, index: number) => {
    setCounter(prev => {
      const cloneCounter = prev.slice();
      cloneCounter[index] = value;
      return cloneCounter;
    });
  }, []);

  return {
    counter,
    isLeftDisabled,
    isRightDisabled,
    onDecrease,
    onItemUpdate,
    onIncrease,
    set: setCounter,
  };
};
